<template>
  <v-row class="roles-and-permission-large full-height">
    <v-col cols="2" class="pb-0 pl-0 pt-1">
      <v-navigation-drawer permanent class="mr-0 pt-4">
        <v-tabs vertical>
          <v-tab
            v-for="(key, index) in roles"
            :key="index"
            class="text-none"
            :class="`roles-tab tab-${key}`"
            @change="changeSelectedRole(index)"
            >{{ getRoles(key) }}</v-tab
          >
        </v-tabs>
      </v-navigation-drawer>
    </v-col>
    <v-col cols="10" class="pb-0">
      <v-container>
        <v-row>
          <v-col cols="10"
            ><div class="title">{{ getRoles(selectedRole) }}</div></v-col
          >
          <v-col cols="2">
            <div
              v-if="showActionButtons && checkPermission('manageRoles')"
              style="float: right"
            >
              <v-btn icon @click="editRole">
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
              <v-btn
                icon
                v-if="
                  (!roleUserCountMap[selectedRole] ||
                    roleUserCountMap[selectedRole] === 0) &&
                  checkPermission('manageRoles')
                "
                @click="deleteRole"
              >
                <v-icon dark>mdi-delete-outline</v-icon> </v-btn
              ><v-tooltip
                v-else-if="checkPermission('manageRoles')"
                :right="!$vuetify.breakpoint.smAndDown"
                :bottom="$vuetify.breakpoint.smAndDown"
                max-width="400"
                class="pa-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <a v-bind="attrs" v-on="on">
                    <v-btn icon disabled @click="deleteRole">
                      <v-icon dark>mdi-delete-outline</v-icon>
                    </v-btn></a
                  ></template
                >
                <span>
                  There are {{roleUserCountMap[selectedRole] ? roleUserCountMap[selectedRole] : 0}} users in this role. Reassign the current users to delete this role.
                </span
              ></v-tooltip></div
          ></v-col>
        </v-row>
        <v-alert
          v-if="showNoPermissionAlert"
          type="warning"
          color="#AD4C0B"
          icon="mdi-alert-outline"
          text
          class="caption font-weight-regular py-3 px-5"
          height="44px"
        >
          {{ $t("noPermissionEnabledWarning") }}
        </v-alert>
        <RolesAndPermissionTreeView
          v-for="(role, i) in roles"
          v-show="selectedRole === role"
          :key="i"
          :items="rolesAndPermissions[role]"
          :role="role"
          @updatePermissions="updatePermissions"
        />
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import rolesAndPermissionsDefaults from "@/static/rolesAndPermissionsDefaults.json";
import UserDataMixin from "@/mixins/UserData";
export default {
  name: "Roles",
  components: {
    RolesAndPermissionTreeView: () =>
      import("./RolesAndPermissionTreeView.vue"),
  },
  mixins: [UserDataMixin],
  props: {
    rolesAndPermissions: {
      type: Object,
      default: () => {},
    },
    roleUserCountMap: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedItem: 0,
      defaultRoles: rolesAndPermissionsDefaults.defaultRoles,
    };
  },
  computed: {
    selectedRole() {
      return this.roles[this.selectedItem];
    },
    roles() {
      const rolesArray = Object.keys(this.rolesAndPermissions);
      if (rolesArray.includes("admin")) {
        const index = rolesArray.indexOf("admin");
        rolesArray.splice(index, 1);
        rolesArray.unshift("admin");
      }
      return rolesArray || [];
    },
    showNoPermissionAlert() {
      if (this.selectedRole) {
        let showAlert = true;
        const currentItem = this.rolesAndPermissions[this.selectedRole];
        currentItem.forEach((element) => {
          if (element.activeNodes && element.activeNodes.length) {
            showAlert = false;
          }
        });
        return showAlert;
      }
      return false;
    },
    showActionButtons() {
      return !this.defaultRoles.includes(this.selectedRole);
    },
  },
  methods: {
    getRoles(role) {
      switch (role) {
        case "admin":
          return this.$t("admin");
        case "user":
          return this.$t("user");
        case "approver":
          return this.$t("approver");
        case "sysop":
          return this.$t("sysop");
        default:
          return role;
      }
    },
    changeSelectedRole(key) {
      this.selectedItem = key;
    },
    updatePermissions(updateObject) {
      this.$emit("updatePermissions", updateObject);
    },
    editRole() {
      this.$emit("editRole", this.selectedRole);
    },
    deleteRole() {
      this.$emit("deleteRole", this.selectedRole);
      this.selectedItem = 0;
    },
  },
};
</script>

<style lang="scss">
@import "../../../scss/components/roles-and-permissions.scss";
.roles-and-permission-large {
  .v-tab {
    justify-content: left;
  }
  .v-expansion-panels {
    border: 1px solid #e0e0e0;
  }
  .v-expansion-panel {
    border-bottom: 1px solid #e0e0e0;
  }
  &.full-height {
    height: calc(100% + 12px);
  }
}
</style>
