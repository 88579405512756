var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "roles-and-permission-large full-height" },
    [
      _c(
        "v-col",
        { staticClass: "pb-0 pl-0 pt-1", attrs: { cols: "2" } },
        [
          _c(
            "v-navigation-drawer",
            { staticClass: "mr-0 pt-4", attrs: { permanent: "" } },
            [
              _c(
                "v-tabs",
                { attrs: { vertical: "" } },
                _vm._l(_vm.roles, function (key, index) {
                  return _c(
                    "v-tab",
                    {
                      key: index,
                      staticClass: "text-none",
                      class: "roles-tab tab-" + key,
                      on: {
                        change: function ($event) {
                          return _vm.changeSelectedRole(index)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.getRoles(key)))]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "pb-0", attrs: { cols: "10" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "10" } }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.getRoles(_vm.selectedRole))),
                    ]),
                  ]),
                  _c("v-col", { attrs: { cols: "2" } }, [
                    _vm.showActionButtons && _vm.checkPermission("manageRoles")
                      ? _c(
                          "div",
                          { staticStyle: { float: "right" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: { click: _vm.editRole },
                              },
                              [_c("v-icon", [_vm._v("mdi-pencil-outline")])],
                              1
                            ),
                            (!_vm.roleUserCountMap[_vm.selectedRole] ||
                              _vm.roleUserCountMap[_vm.selectedRole] === 0) &&
                            _vm.checkPermission("manageRoles")
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "" },
                                    on: { click: _vm.deleteRole },
                                  },
                                  [
                                    _c("v-icon", { attrs: { dark: "" } }, [
                                      _vm._v("mdi-delete-outline"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm.checkPermission("manageRoles")
                              ? _c(
                                  "v-tooltip",
                                  {
                                    staticClass: "pa-0",
                                    attrs: {
                                      right: !_vm.$vuetify.breakpoint.smAndDown,
                                      bottom: _vm.$vuetify.breakpoint.smAndDown,
                                      "max-width": "400",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "a",
                                                _vm._g(
                                                  _vm._b({}, "a", attrs, false),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        disabled: "",
                                                      },
                                                      on: {
                                                        click: _vm.deleteRole,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { dark: "" } },
                                                        [
                                                          _vm._v(
                                                            "mdi-delete-outline"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2048513228
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " There are " +
                                          _vm._s(
                                            _vm.roleUserCountMap[
                                              _vm.selectedRole
                                            ]
                                              ? _vm.roleUserCountMap[
                                                  _vm.selectedRole
                                                ]
                                              : 0
                                          ) +
                                          " users in this role. Reassign the current users to delete this role. "
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _vm.showNoPermissionAlert
                ? _c(
                    "v-alert",
                    {
                      staticClass: "caption font-weight-regular py-3 px-5",
                      attrs: {
                        type: "warning",
                        color: "#AD4C0B",
                        icon: "mdi-alert-outline",
                        text: "",
                        height: "44px",
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("noPermissionEnabledWarning")) + " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._l(_vm.roles, function (role, i) {
                return _c("RolesAndPermissionTreeView", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selectedRole === role,
                      expression: "selectedRole === role",
                    },
                  ],
                  key: i,
                  attrs: { items: _vm.rolesAndPermissions[role], role: role },
                  on: { updatePermissions: _vm.updatePermissions },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }